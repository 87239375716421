import axios from "axios";

function getCookie(cookieName) {
  const name = cookieName + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(";");

  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i].trim();
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }

  return null; // Return null if the cookie with the specified name is not found
}

// Merchant and RMS Config
export function configAPI(encipherencode, cname) {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/get/merchant/configurations`,
    {
      params: {
        encipherencode: encipherencode,
        cname: cname,
      },
    }
  );
}

// Send OTP
export function sendOTPRequest(email, merchant_id, phone) {
  return axios.post(`${process.env.REACT_APP_API_URL}/send-otp`, {
    merchant_id: merchant_id,
    email: email,
    phonenumber: phone,
  });
}

// OTP Verification
export function verifyOTP(phone, unique_id, otp, email) {
  return axios.post(`${process.env.REACT_APP_API_URL}/verify-otp`, {
    unique_id: unique_id,
    otp: otp,
    phonenumber: phone,
    email: email,
  });
}

// Resend OTP
export function resendOTP(merchant_id, unique_id, phone, email) {
  return axios.post(`${process.env.REACT_APP_API_URL}/resend-otp`, {
    merchant_id: merchant_id,
    unique_id: unique_id,
    phonenumber: phone,
    email: email,
  });
}

// Orders List
export function ordersList(
  merchant_id,
  userPhone,
  userEmail,
  offset,
  order_name
) {
  return axios.get(`${process.env.REACT_APP_API_URL}/orders/`, {
    params: {
      phonenumber: userPhone,
      merchant_id: merchant_id,
      email: userEmail,
      offset: offset,
      limit: 50,
      order_name: order_name,
    },
  });
}

// Return Requests List
export function returnRequestsAPI(merchant_id, userPhone, userEmail, offSet) {
  return axios.get(`${process.env.REACT_APP_API_URL}/rms/return/requests`, {
    params: {
      phonenumber: userPhone,
      merchant_id: merchant_id,
      email: userEmail,
      offset: offSet,
      limit: 10,
    },
  });
}

// Delivery Status Check
export function deliveryStatusCheck(merchant_id, order_id) {
  return axios.get(`${process.env.REACT_APP_API_URL}/orders/order-status`, {
    params: {
      merchant_id,
      order_id,
    },
  });
}

// Pincode Serviceabilty Check
export function serviceabiltyCheck(pincode, merchant_id) {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/rms/check/serviceability`,
    {
      params: {
        merchant_id: merchant_id,
        pincode: pincode,
      },
    }
  );
}

// Selected Order details
export function selectedOrderAPI(merchant_id, order_id, selectedOrder) {
  const body = {
    merchant_id: merchant_id,
    order_id: order_id,
    order: selectedOrder,
  };
  return axios.post(`${process.env.REACT_APP_API_URL}/orders/order`, body);
}

// Selected Return Request details
export function selectedReturnOrderAPI(
  merchant_id,
  id,
  reference_id,
  create_exchange_order
) {
  let params = {
    merchant_id: merchant_id,
    create_exchange_order: create_exchange_order,
  };
  if (id && id !== null && id !== "") {
    params.id = id;
  }
  if (reference_id && reference_id !== null && reference_id !== "") {
    params.reference_id = reference_id;
  }
  return axios.get(`${process.env.REACT_APP_API_URL}/rms/return/request`, {
    params: params,
  });
}

// Return Reasons
export function getReasons(merchant_id, return_type) {
  return axios.get(`${process.env.REACT_APP_API_URL}/rms/return/reasons`, {
    params: {
      merchant_id: merchant_id,
      return_type: return_type,
    },
  });
}

// Same Variant Products
export function getSameVariants(
  merchant_id,
  product_id,
  compare_inventory_qty,
  compare_variant_price,
  variant_id,
  variant_sku,
  lower_value,
  higher_value,
  api_version
) {
  let domain = process.env.REACT_APP_API_URL;
  if (api_version === "v2") {
    domain = domain.replace("/v1", `/${api_version}`);
  }
  return axios.get(
    `${domain}/products/product_variants`,
    {
      params: {
        merchant_id,
        product_id: String(product_id),
        compare_inventory_qty,
        compare_variant_price,
        variant_id,
        variant_sku,
        lower_value:
          lower_value === undefined ? undefined : lower_value ? "yes" : "no",
        higher_value:
          higher_value === undefined ? undefined : higher_value ? "yes" : "no",
      },
    }
  );
}

// Different Variant Products
export function getDifferentVariants(
  merchant_id,
  product_id,
  compare_inventory_qty,
  compare_variant_price,
  offset,
  collection_id,
  query,
  sku_query,
  lower_value,
  higher_value,
  variant_id,
  variant_sku,
  api_version
) {
  let domain = process.env.REACT_APP_API_URL;
  if (api_version === "v2") {
    domain = domain.replace("/v1", `/${api_version}`);
  }
  return axios.get(`${domain}/products/products`, {
    params: {
      merchant_id,
      product_id: String(product_id),
      compare_inventory_qty,
      compare_variant_price,
      limit: 10,
      offset,
      collection_id,
      query,
      sku_query,
      variant_id,
      variant_sku,
      lower_value:
        lower_value === undefined ? undefined : lower_value ? "yes" : "no",
      higher_value:
        higher_value === undefined ? undefined : higher_value ? "yes" : "no",
    },
  });
}

// Different Product Collections
export function getCollections(merchant_id) {
  return axios.get(`${process.env.REACT_APP_API_URL}/products/collections`, {
    params: {
      merchant_id,
    },
  });
}

// Create Return Request
export function createRequest(
  merchant_id,
  selectedItem,
  shipmentMethod,
  returnedProducts,
  rmsConfig,
  addressDetails,
  upload_media,
  return_type,
  refundSource,
  refund_json,
  userEmail,
  userPhoneNumber,
  firstName,
  lastName,
  reference_id,
  uploaded_media_ids,
  refund_charges,
  exchange_charges,
  shipping_charges,
  unique_id,
  offer_refund_mode,
  offer_refund_mode_on,
  refund_mode_offer_amount,
  button_version
) {
  const body = {
    merchant_id,
    order_id: selectedItem[0].order_id,
    order_number: selectedItem[0].order_number,
    order_name: selectedItem[0].order_name,
    return_type,
    customer_preferred_refund_method: refundSource,
    customer_preferred_return_shipment_method: shipmentMethod,
    customer_first_name: firstName,
    customer_last_name: lastName,
    customer_email: userEmail,
    customer_phone_number: userPhoneNumber,
    customer_address_line_1: addressDetails.addressLine1,
    customer_address_line_2: addressDetails.addressLine2,
    customer_address_city: addressDetails.city,
    customer_address_pin: addressDetails.pincode,
    customer_address_state: addressDetails.state,
    customer_address_country:
      selectedItem[0].shipping_address.country ||
      selectedItem[0].billing_address.country,
    product_tag_exists: rmsConfig.product_tag_consent,
    line_items: returnedProducts,
    bank_transfer_charge:
      rmsConfig.handling_charges.refund_handling_charges.bank_transfer,
    coupon_charge: rmsConfig.handling_charges.refund_handling_charges.coupon,
    exchange_shipment_charge:
      rmsConfig.handling_charges.exchange_handling_charges,
    return_shipment_charge:
      rmsConfig.handling_charges.return_shipment_handling_charges,
    reimburse_amount:
      rmsConfig.handling_charges.return_shipment_reimbursement_charges,
    refund_json,
    reference_id,
    uploaded_media_ids,
    ga_client_id: getCookie("_ga"),
    refund_charges,
    exchange_charges,
    shipping_charges,
    unique_id,
    offer_refund_mode,
    offer_refund_mode_on,
    refund_mode_offer_amount,
    button_version
  };
  return axios.post(
    `${process.env.REACT_APP_API_URL}/rms/create/return_request`,
    body,
    {
      params: {
        upload_media,
      },
    }
  );
}

// Upload Media
export function uploadMedia(merchant_id, return_request_id, files) {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/rms/create/return_request/upload_media`,
    files,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      params: {
        merchant_id,
        return_request_id,
      },
    }
  );
}

// Validate Media Type
export function validateMedia(
  files,
  merchant_id,
  check_reasons,
  reason_id,
  reason_code,
  reason_type,
  order_id,
  order_number
) {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/rms/create/return_request/validate_media`,
    files,
    {
      params: {
        merchant_id,
        reason_id,
        reason_code,
        reason_type,
        check_reasons,
        order_id,
        order_number,
      },
    }
  );
}

// Comments list
export function getComments(return_request_id) {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/rms/return_request/comments`,
    {
      params: {
        return_request_id,
      },
    }
  );
}

// Create Comment
export function createComment(return_request_id, comment, upload_media) {
  const body = {
    return_request_id,
    commented_by: "customer",
    comment,
  };
  return axios.post(
    `${process.env.REACT_APP_API_URL}/rms/return_request/comment/create`,
    body,
    {
      params: {
        upload_media,
      },
    }
  );
}

// Comment Media Upload
export function uploadCommentMedia(return_request_id, comment_id, files) {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/rms/create/return_request/comment/upload_media`,
    files,
    {
      params: {
        return_request_id,
        comment_id,
      },
    }
  );
}

// Update Shipping Details
export function updateShippingDetails(file) {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/rms/return_request/self_ship/details/update`,
    file
  );
}

// Check for amount to be paid and payment method to be used
export function paymentValidate(
  merchant_id,
  order_number,
  order_id,
  is_cod,
  total_order_price,
  return_type,
  shipmentMethod,
  returnedProducts,
  refundSource,
  preapplied_exchange_charge,
  preapplied_refund_charge
) {
  const body = {
    merchant_id,
    order_number,
    order_id,
    is_cod,
    total_order_price,
    return_type,
    line_items: returnedProducts,
    customer_preferred_return_shipment_method: shipmentMethod,
    customer_preferred_refund_method: refundSource,
    preapplied_exchange_charge,
    preapplied_refund_charge,
  };
  return axios.post(
    `${process.env.REACT_APP_API_URL}/rms/return_request/payment/validate`,
    body
  );
}

// Prepaid payment method api
export function differencePayment(
  merchant_id,
  customer_first_name,
  customer_last_name,
  amount,
  email,
  phonenumber,
  order_id,
  order_number,
  return_type,
  selectedItem,
  shipmentMethod,
  returnedProducts,
  rmsConfig,
  addressDetails,
  refundSource,
  refund_json,
  userEmail,
  userPhoneNumber,
  reference_id,
  uploaded_media_ids,
  customer_id,
  refund_charges,
  exchange_charges,
  shipping_charges,
  unique_id,
  button_version
) {
  const body = {
    paymentData: {
      encipherencode: sessionStorage.getItem("encipherencode"),
      merchant_id,
      customer_first_name,
      customer_last_name,
      amount: amount,
      customer_email: email,
      customer_phone_number: phonenumber,
      order_id,
      order_number,
      return_type: return_type,
      customer_id,
    },
    requestData: {
      merchant_id,
      order_id: selectedItem[0].order_id,
      order_number: selectedItem[0].order_number,
      order_name: selectedItem[0].order_name,
      return_type,
      customer_preferred_refund_method: refundSource,
      customer_preferred_return_shipment_method: shipmentMethod,
      customer_first_name,
      customer_last_name,
      customer_email: userEmail,
      customer_phone_number: userPhoneNumber,
      customer_address_line_1: addressDetails.addressLine1,
      customer_address_line_2: addressDetails.addressLine2,
      customer_address_city: addressDetails.city,
      customer_address_pin: addressDetails.pincode,
      customer_address_state: addressDetails.state,
      customer_address_country:
        selectedItem[0].shipping_address.country ||
        selectedItem[0].billing_address.country,
      product_tag_exists: rmsConfig.product_tag_consent,
      line_items: returnedProducts,
      bank_transfer_charge:
        rmsConfig.handling_charges.refund_handling_charges.bank_transfer,
      coupon_charge: rmsConfig.handling_charges.refund_handling_charges.coupon,
      exchange_shipment_charge:
        rmsConfig.handling_charges.exchange_handling_charges,
      return_shipment_charge:
        rmsConfig.handling_charges.return_shipment_handling_charges,
      reimburse_amount:
        rmsConfig.handling_charges.return_shipment_reimbursement_charges,
      refund_json,
      reference_id,
      uploaded_media_ids,
      ga_client_id: getCookie("_ga"),
      refund_charges,
      exchange_charges,
      shipping_charges,
      unique_id,
      button_version
    },
  };
  return axios.post(
    `${process.env.REACT_APP_API_URL}/rms/return_request/payment/create`,
    body
  );
}

// Check the status of payment
export function paymentStatus(
  merchant_id,
  transaction_id,
  reference_id,
  order_id,
  upload_media,
  create_request
) {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/rms/return_request/payment/status`,
    {
      params: {
        merchant_id,
        transaction_id,
        reference_id,
        order_id,
        upload_media,
        create_request,
      },
    }
  );
}

// Submit details

export function submitDetailsApi(
  merchant_id,
  return_request_id,
  refund_method,
  bank_account_holder_name,
  bank_account_number,
  confirm_bank_account_number,
  bank_ifsc_code,
  vpa_id
) {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/rms/return_request/collect/customer_account_details`,
    {
      merchant_id,
      return_request_id,
      refund_method,
      bank_account_holder_name,
      bank_account_number,
      confirm_bank_account_number,
      bank_ifsc_code,
      vpa_id,
    }
  );
}
