import styled from "styled-components";

export const StyledReturnCart = styled.div`
  font-family: ${(props) => props.customFont};
  .select {
    font-size: 16px;
    color: #333;
    font-weight: 600;
    margin-left: 20px;
    margin-bottom: 10px;
    text-decoration-line: underline;
    text-underline-offset: 16px;
    text-decoration-color: #000;
    text-decoration-thickness: 2px;
  }

  .return-request {
    padding: 15px;
    display: flex;
    justify-content: space-between;
    box-shadow: 0px -20px 40px 0px rgba(0, 0, 0, 0.10);
    align-items: center;
    position: fixed;
    width: 100%;
    z-index: 7;
    background: #fff;
    bottom: 25px;

    .product-number {
      font-size: 14px;
    }
  }

  .request-button {
    border-radius: ${(props) => (props.sharpEdge ? "0px" : "4px")};
    margin-left: 10px;
    padding: 10px;
    font-weight: 600;
    font-size: 12px;
    text-transform: capitalize;
    width: 165px;
  }

  .help {
    font-weight: 600;
    font-size: 12px;
    color: var(--color-primary);
    margin: 0px 20px 100px;
    text-decoration: underline;
  }

  .cart-products {
    padding: 20px 0px 0px;
  }

  .overlay {
    position: fixed;
    height: 100vh;
    bottom: 0px;
    background: #fff;
    width: 100%;
    z-index: 99;
    opacity: 0.6;
  }

  .pricing{
    margin-left: 20px !important;
  }

  @media (min-width: 800px) {
    .select {
      display: flex;
      padding: 20px 0px 10px 14%;
      font-size: 20px;
      margin-left: 0px;
    }
    .pricing{
      margin-left: 0px !important;
    }
    .return-request {
      padding: 100px 5.5% 20px;
      top: 0;
      bottom: initial;
      font-size: 20px;
      max-width: 1920px;
      box-shadow: 0px 0px 20px rgba(0,0,0,0.2);

      .product-number {
        font-size: 20px;
      }
    }
    .request-button {
      padding: 1rem;
      margin-left: 10px;
      font-size: 14px;
      width: 220px;
    }
    .help {
      margin: 60px 5.5%;
    }
    .cart-products {
      padding: 20px 0px 0px 14%;
    }
    .overlay {
      top: 80px;
      bottom: initial;
    }
  }
`;

export const StyledExchange = styled.div`
  padding: ${(props) =>
    props.location === "/success" ? "30px 0px 0px" : "100px 0px 0px"};
  position: relative;

  .cart-wrapper {
    padding: 10px 20px;
    background: #fff;

    .exchange-wrapper {
      display: flex;
      background: #fff;
      flex-direction: row;
      justify-content: space-between;

      .exchange-box {
        display: flex;
        padding: 10px 0px;
        border-radius: 4px;
        flex-direction: column;
        flex: 0.45;

        .mob-ex {
          font-size: 12px;
          margin: 5px 0px 10px;
          color: #333;
          font-weight: 500;
        }

        .upto-refund,
        .conditions {
          font-size: 12px;
        }

        .image-preview {
          width: 60px;
          height: 60px;
          object-fit: contain;
          object-position: left;
          border-radius: 4px;
        }

        .description,
        .quantity {
          font-size: 12px;
          margin: 10px 0;
          color: #333333b2;
        }

        .title,
        .price {
          font-size: 14px;
          margin: 10px 0;
          font-weight: 500;
          color: #333;
        }

        .stock {
          color: red;
          margin-left: auto;
        }
      }

      .return-icon {
        display: flex;
        align-items: center;
        flex: 0.1;
        margin: 0 10px;
      }
    }

    .return-type,
    .return-reason {
      font-size: 14px;
    }
  }

  .pricing-padding {
    padding: 0px 16px;
    font-size: 1rem;
  }

  .price-details {
    padding: 20px 0px 120px;
    flex-direction: column;
    display: flex;
    width: 100%;
    color: #333;
    position: relative;

    #Returnmode{
      border-bottom: ${(props)=>props.refundModeError ? '1px solid red' : 'none'};
    }

    .box {
      border-radius: 4px;
      padding: 20px;
      background: #fff;
      margin: 10px 0px;
      width: 100%;

      .title {
        font-size: 14px;
        font-weight: 500;
      }

      .know {
        font-size: 12px;
      }

      .radio-label, .refund-preference, .refund-note{
        font-size: 13px;
      }
    }

    .address {
      padding: 0px !important;
    }
  }

  .pricing {
    font-size: 16px;
    margin: 0px 20px 20px;
    text-decoration-line: underline;
    text-underline-offset: 16px;
    text-decoration-color: #000;
    text-decoration-thickness: 2px;
  }

  .product-details {
    color: #333333;
    font-weight: 500;
    font-size: 1rem;
    margin: 0px 20px 20px;
  }

  @media (min-width: 800px) {
    padding: ${(props) =>
      props.location === "/success" ? "30px 120px 0px" : "200px 0px 0px"};
    display: flex;

    .products-return {
      flex: 0.45;
      padding-bottom: 25px;

      .product-display {
        padding: ${(props) =>
          props.returnedProducts !== undefined &&
          props.returnedProducts.length > 0
            ? "25px 0px 0px 0px"
            : "2px 0px 0px 10%"};
      }
    }

    .pricing-padding {
      padding: 0px;
      font-size: 1.25rem;
    }

    .product-details {
      font-size: 1.25rem;
      margin: 20px 0px 0px;
    }

    .cart-wrapper {
      padding: 0px 0px 10px;
      margin: 0px 0px 10px;
      border-bottom: ${(props) =>
        props.outOfStock !== undefined &&
        props.outOfStock !== null &&
        props.returnedProducts
          .filter((a) => a.variant_id)
          .indexOf(props.outOfStock)
          ? `1px solid red`
          : "1px solid #11111133"};
      border-radius: 4px;

      .exchange-wrapper {
        .exchange-box {
          flex: 0.45;
          .mob-ex,
          .upto-refund,
          .conditions {
            font-size: 16px;
          }
          .details {
            padding: 0px 0px;
            position: relative;
            width: 100%;

            .title,
            .description,
            .quantity,
            .price {
              font-size: 16px;
            }
          }
        }

        .return-icon {
          margin: 0 20px;
        }
      }

      .return-type,
      .return-reason {
        font-size: 1rem;
      }
    }

    .pricing {
      align-items: start;
      font-size: 1.25rem;
      margin: 20px 0px 0px;
    }

    .price-details {
      padding: ${(props) =>
        props.location === "/return-cart" ? "25px 5.5%" : "0px 5.5%"};
      visibility: ${(props) => (!props.webSelected ? "visible" : "hidden")};
      flex: 0.45;

      #Returnmode{
        border-bottom: ${(props)=>props.refundModeError ? '1px solid red' : '1px solid #11111133'};
      }

      .box {
        border-bottom: 1px solid #11111133;
        justify-content: space-between;
        padding: 0px 0px 20px;
        .title {
          font-size: 18px;
          margin: 0px;
        }
        .know {
          font-size: 16px;
        }
        .radio-label, .refund-preference, .refund-note{
          font-size: 16px;
        }
      }

      .address {
        padding: 20px 0px !important;
      }
    }
  }
`;

export const StyledPaymentDiv = styled.div`
  background: #fff;
  padding: 20px;

  .payment-title{
    font-size: 14px;
    color: #333;
    font-weight: 500;
    margin-bottom: 12px;
  }

  .subtotal-statement{
    font-size: 12px;
  }
  
  @media (min-width: 800px) {
    padding: 10px 0px;
    .payment-title{
      font-size: 1.15rem
    }
    .subtotal-statement{
      font-size: 16px;
    }
  }
`
